@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

	  .font-poppins {
    font-family: var(--font-poppins);
  }


	:root {
		--color-tailwind-background-patient: 252 252 252 !important;
		--color-tailwind-background-doctor: 245 247 249 !important;

		--color-tailwind-primary: 53 98 255 !important; /* primary-500 */
		--color-tailwind-primary--hover: 38 70 181 !important; /* primary-700 */
		--color-tailwind-primary--pressed: 192 206 255 !important; /* primary-100 */
		--color-tailwind-primary-light: 208 229 253 !important;
		--color-tailwind-primary-25: 241 244 255 !important;
		--color-tailwind-primary-50: 235 239 255 !important;
		--color-tailwind-primary-100: 192 206 255 !important;
		--color-tailwind-primary-200: 162 183 255 !important;
		--color-tailwind-primary-300: 120 150 255 !important;
		--color-tailwind-primary-400: 93 129 255 !important;
		--color-tailwind-primary-500: 53 98 255 !important;
		--color-tailwind-primary-600: 48 89 232 !important;
		--color-tailwind-primary-700: 38 70 181 !important;
		--color-tailwind-primary-800: 29 54 140 !important;
		--color-tailwind-primary-900: 22 41 107 !important;

		--color-tailwind-secondary: 86 63 240 !important; /* secondary-500 */
		--color-tailwind-secondary--hover: 67 49 186 !important; /* secondary-700 */
		--color-tailwind-secondary--pressed: 231 230 252 !important; /* secondary-100 */
		--color-tailwind-secondary-25: 248 248 255 !important;
		--color-tailwind-secondary-50: 243 243 254 !important;
		--color-tailwind-secondary-100: 231 230 252 !important;
		--color-tailwind-secondary-200: 205 202 249 !important;
		--color-tailwind-secondary-300: 174 168 246 !important;
		--color-tailwind-secondary-400: 138 127 243 !important;
		--color-tailwind-secondary-500: 86 63 240 !important;
		--color-tailwind-secondary-600: 77 56 215 !important;
		--color-tailwind-secondary-700: 67 49 186 !important;
		--color-tailwind-secondary-800: 54 40 152 !important;
		--color-tailwind-secondary-900: 38 28 107 !important;

		--color-tailwind-tertiary: 255 193 0 !important; /* tertiary-500 */
		--color-tailwind-tertiary--hover: 181 137 0 !important; /* tertiary-700 */
		--color-tailwind-tertiary--pressed: 255 236 176 !important; /* tertiary-100 */
		--color-tailwind-tertiary-50: 233 235 248 !important;
		--color-tailwind-tertiary-100: 255 236 176 !important;
		--color-tailwind-tertiary-200: 255 226 138 !important;
		--color-tailwind-tertiary-300: 255 213 84 !important;
		--color-tailwind-tertiary-400: 255 205 51 !important;
		--color-tailwind-tertiary-500: 255 193 0 !important;
		--color-tailwind-tertiary-600: 232 176 0 !important;
		--color-tailwind-tertiary-700: 181 137 0 !important;
		--color-tailwind-tertiary-800: 140 106 0 !important;
		--color-tailwind-tertiary-900: 107 81 0 !important;

		--color-tailwind-accent: 121 57 204 !important; /* accent-500 */
		--color-tailwind-accent--hover: 91 43 153 !important; /* accent-700 */
		--color-tailwind-accent--pressed: 239 227 255 !important; /* accent-100 */
		--color-tailwind-accent-50: 245 237 255 !important;
		--color-tailwind-accent-100: 239 227 255 !important;
		--color-tailwind-accent-200: 223 198 255 !important;
		--color-tailwind-accent-300: 151 71 255 !important;
		--color-tailwind-accent-400: 136 64 230 !important;
		--color-tailwind-accent-500: 121 57 204 !important;
		--color-tailwind-accent-600: 113 53 191 !important;
		--color-tailwind-accent-700: 91 43 153 !important;
		--color-tailwind-accent-800: 68 32 115 !important;
		--color-tailwind-accent-900: 53 25 89 !important;
		
		--color-tailwind-success: 0 195 147 !important; /* success-500 */
		--color-tailwind-success-hover: 0 138 104 !important; /* success-700 */
		--color-tailwind-success-1: 0 138 104 !important; 
		--color-tailwind-success-50: 230 249 244 !important;
		--color-tailwind-success-100: 176 236 222 !important;
		--color-tailwind-success-200: 138 227 205 !important;
		--color-tailwind-success-300: 84 215 183 !important;
		--color-tailwind-success-400: 51 207 169 !important;
		--color-tailwind-success-500: 10 191 143 !important;
		--color-tailwind-success-600: 0 177 134!important;
		--color-tailwind-success-700: 0 138 104 !important;
		--color-tailwind-success-800: 0 107 81 !important;
		--color-tailwind-success-900: 0 82 62 !important;

		--color-tailwind-warning: 255 226 91 !important; /* warning-500 */
		--color-tailwind-warning-hover: 181 160 65 !important; /* warning-700 */
		--color-tailwind-warning-50: 255 252 239 !important;
		--color-tailwind-warning-100: 255 246 204 !important;
		--color-tailwind-warning-200: 255 242 180 !important;
		--color-tailwind-warning-300: 255 236 145 !important;
		--color-tailwind-warning-400: 255 232 124 !important;
		--color-tailwind-warning-500: 255 226 91 !important;
		--color-tailwind-warning-600: 232 206 83 !important;
		--color-tailwind-warning-700: 181 160 65 !important;
		--color-tailwind-warning-800: 140 124 50 !important;
		--color-tailwind-warning-900: 107 95 38!important;

		--color-tailwind-error: 229 64 85 !important; /* error-500 */
		--color-tailwind-error-hover: 163 45 60 !important; /* error-700 */
		--color-tailwind-error-50: 252 236 238 !important;
		--color-tailwind-error-100: 247 196 202 !important;
		--color-tailwind-error-200: 243 167 177 !important;
		--color-tailwind-error-300: 238 127 141 !important;
		--color-tailwind-error-400: 234 102 119 !important;
		--color-tailwind-error-500: 229 64 85 !important;
		--color-tailwind-error-600: 208 58 77 !important;
		--color-tailwind-error-700: 163 45 60 !important;
		--color-tailwind-error-800: 126 35 47 !important;
		--color-tailwind-error-900: 96 27 36 !important;

		--color-tailwind-grey: 91 65 65 !important;
		--color-tailwind-grey--hover: 69 90 100 !important; /* grey-700 */
		--color-tailwind-grey-1: 51 51 51 !important;
		--color-tailwind-grey-2: 79 79 79 !important;
		--color-tailwind-grey-3: 130 130 130 !important;
		--color-tailwind-grey-4: 189 189 189 !important;
		--color-tailwind-grey-5: 224 224 224 !important;
		--color-tailwind-grey-6: 242 242 242 !important;
		--color-tailwind-grey-10: 254 254 254 !important;

		--color-tailwind-grey-25: 246 246 246 !important;
		--color-tailwind-grey-50: 236 239 241 !important;
		--color-tailwind-grey-100: 207 216 220 !important;
		--color-tailwind-grey-200: 176 190 197 !important;
		--color-tailwind-grey-300: 144 164 174 !important;
		--color-tailwind-grey-400: 120 144 156 !important;
		--color-tailwind-grey-500: 96 125 139 !important;
		--color-tailwind-grey-600: 84 110 122 !important;
		--color-tailwind-grey-700: 69 90 100 !important;
		--color-tailwind-grey-800: 51 71 79 !important;
		--color-tailwind-grey-900: 38 50 56 !important;

		--color-tailwind-grey-chat: 231 230 252 !important;
		--color-tailwind-blue-chat: 86 63 240 !important;
		--color-tailwind-header-background: var(--color-tailwind-grey-10);
		--color-tailwind-header-foreground: var(--color-tailwind-primary);
		--color-tailwind-header-notifications: var(--color-tailwind-grey-1);
		--color-tailwind-green-ws: 37 211 102;
		/* Deprecated from Occipital-Ui */
		--color-primary-highlight: rgb(163 216 231);
		--color-text-primary: rgb(50 51 56);
		--color-text-secondary: rgb(111 111 111);
		--color-text-link: rgb(73 128 145);
		--color-text-error: rgb(231 29 29);
		--color-background-light: rgb(254 254 254);
		--color-state-sucess: rgb(37 155 56);
		--color-state-error: rgb(231 29 29);
		--color-state-warning: rgb(255 183 0);
		--color-state-sucess-hover: rgb(38 138 55);
		--color-state-error-hover: rgb(210 37 37);
		--color-state-warning-hover: rgb(228 166 10);
		--color-gray-800: rgb(45 55 72);
		--color-red-600: rgb(215 39 39);
		--color-green-500: rgb(72 187 120);
		--spacing-xs: 8px;
		--font-size-xxs: 0.9rem;
		--font-size-xs: 1rem;
		--font-weight-normal: 400;
		--font-weight-semibold: 600;
		--box-shadow-xs: 0px 3px 6px rgba(0, 0, 0, 0.05);
		--border-radius-xs: 5px;
		--border-radius-sm: 8px;
		--border-radius-lg: 30px;
		--border-radius-full: 50%;
		--border-width-none: 0;
		--border-width-thin: 1px;
		--border-width-thick: 2px;
		--opacity-25: 0.25;
		--color-font-base: rgb(41, 41, 41);
		--color-secondary-highlight: rgb(182, 83, 245);
		/* Deprecated from occipital-ui */
		--color-primary: rgb(53 98 255) !important;
		--color-primary--hover: rgb(0 45 227) !important;
		--color-primary--pressed: rgb(196 200 254) !important;
		--color-secondary: rgb(161 61 223) !important;
		--color-secondary--hover: rgb(100 18 203) !important;
		--color-secondary--pressed: rgb(222 188 243) !important;
		--color-tertiary: rgb(255 211 0) !important;
		--color-tertiary--hover: rgb(255 211 0) !important;
		--color-tertiary--pressed: rgb(255 248 191) !important;
		--color-success: rgb(37 155 56) !important;
		--color-success-hover: rgb(38 138 55) !important;
		--color-warning: rgb(255 183 0) !important;
		--color-warning-hover: rgb(228 166 10) !important;
		--color-error: rgb(231 29 29) !important;
		--color-error-hover: rgb(210 37 37) !important;
		--color-grey: rgb(91 65 65) !important;
		--color-grey-1: rgb(51 51 51) !important;
		--color-grey-2: rgb(79 79 79) !important;
		--color-grey-3: rgb(130 130 130) !important;
		--color-grey-4: rgb(189 189 189) !important;
		--color-grey-5: rgb(224 224 224) !important;
		--color-grey-6: rgb(242 242 242) !important;
	}

	.IOMA_THEME {
		--color-tailwind-primary: 84 147 167 !important;
		--color-tailwind-primary-hover: 49 87 98 !important;
		--color-tailwind-primary-pressed: 53 95 108 !important;

		--color-tailwind-header-background: var(--color-tailwind-primary);
		--color-tailwind-header-foreground: var(--color-tailwind-grey-10);
		--color-tailwind-header-notifications: var(--color-tailwind-grey-10);

		/* Deprecated from Occipital-Ui and custom scss */
		--color-primary: rgb(84 147 167) !important;
		--color-primary-hover: rgb(49 87 98) !important;
		--color-primary-pressed: rgb(53 95 108) !important;
	}

	.FARMATODO_THEME {
		--color-tailwind-primary: 0 46 98 !important;
		--color-tailwind-primary-hover: 65 143 222 !important;
		--color-tailwind-primary-pressed: 196 200 254 !important;
		--color-tailwind-secondary: 190 92 125 !important;

		--color-tailwind-header-background: var(--color-tailwind-grey-10);
		--color-tailwind-header-foreground: var(--color-tailwind-primary);
		--color-tailwind-header-notifications: var(--color-tailwind-grey-1);

		

		/* Deprecated from Occipital-Ui and custom scss */
		--color-primary: #418fde !important;
		--color-primary-hover: #418fde !important;
	}

	/* Dynamic height */
	.h-screen {
		height: 100vh; /* Fallback for browsers that do not support Custom Properties */
		height: 100dvh !important;
	}
}

@layer components {
	.specialtieButton > div {
		@apply w-full flex justify-between items-center text-black;
	}
}

.bottom {
    width: calc(100% - 32px);
    position: fixed;
    bottom: 20px;
    text-align: center;
    background-color: white;
}
